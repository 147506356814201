.ts-control {
  @apply items-center min-h-9 w-full px-3.5 py-1 rounded-md border-gray-300 bg-gray-100 text-gray-900 transition-colors;
}

.ts-wrapper.dropdown-active .ts-control {
  @apply rounded-b-none;
}

.full .ts-control {
  @apply bg-gray-100;
}

.ts-wrapper.single.input-active .ts-control {
  @apply bg-gray-100;
}

.ts-wrapper.focus .ts-control {
  @apply border-green-600 bg-gray-100;
}

.ts-control input {
  @apply h-6 min-w-4 placeholder:text-gray-500;
}

.ts-wrapper.plugin-clear_button .item,
.ts-wrapper.plugin-remove_button .item, .ts-wrapper.multi .ts-control>div {
  @apply px-1.5 py-1 -ml-1.5 mr-2.5 rounded-md bg-blue-900 text-white text-13/4 font-medium;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item {
  @apply pr-1.5 !important;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
  @apply ml-1.5 border-l-0;
}

.ts-wrapper.plugin-remove_button .item .remove {
  @apply w-3 h-5 ml-1.5 -my-1 p-0 bg-transparent border-0 text-lg/none hover:text-white/70 transition-colors;
}

.ts-dropdown {
  @apply border-gray-300 text-gray-900 mt-0 rounded-b-md;
}

.ts-dropdown .active {
  @apply bg-gray-100 text-gray-900;
}

.ts-dropdown .spinner::after {
  @apply border-t-green-600 border-r-transparent border-b-green-600 border-l-transparent;
}

.plugin-clear_button .clear-button {
  @apply text-xl;
}

.ts-dropdown .create {
  @apply bg-green-600/10 border border-green-600 text-green-600 px-2 py-3 transition-colors;
}

.ts-dropdown .active.create,
.ts-dropdown .active.create:hover {
  @apply bg-green-600 text-white;
}

.ts-dropdown .active.create strong {
  @apply font-medium;
}
