/* CSS from node_modules */
@import "tom-select/dist/css/tom-select.css";
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'swiper/swiper-bundle.min.css';

/* Local CSS files */
@import "animations";
@import "pagy";
@import "tom-select-custom";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/helveticaneueroman.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/helveticaneueitalic.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/helveticaneuebold.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/helveticaneuebolditalic.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/helveticaneuemedium.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/helveticaneuemediumitalic.woff2) format('woff2');
  }
}

body {
  @apply min-h-screen flex flex-col;
}

body:has(.modal) {
  overflow: hidden;
}

.button-active {
  @apply bg-blue-500 text-white;
}

.animate-fade-out {
  animation: appear-then-fade 4s both;
}

.search-results-table tbody tr:nth-child(8n-5),
.search-results-table tbody tr:nth-child(8n-4),
.search-results-table tbody tr:nth-child(8n-1),
.search-results-table tbody tr:nth-child(8n) {
  @apply bg-gray-100;
}

.search-results-table tbody tr td:last-child {
  @apply bg-white;
}

.search-results-table tbody tr:nth-child(8n-5) td:last-child,
.search-results-table tbody tr:nth-child(8n-4) td:last-child,
.search-results-table tbody tr:nth-child(8n-1) td:last-child,
.search-results-table tbody tr:nth-child(8n) td:last-child {
  @apply bg-gray-100;
}

.testimonials-slider .swiper-pagination-bullet {
  @apply bg-white opacity-20 w-5.5 h-1.5 rounded-full hover:opacity-100 transition-opacity;
}

.testimonials-slider .swiper-pagination-bullet-active {
  @apply opacity-100;
}

.plans-toggle .button-active {
  @apply text-white bg-gray-900 border-gray-300 shadow-sm;
}

.pricing-features li b {
  @apply font-medium;
}

.vertical-tabs .active {
  @apply bg-white text-gray-900 border-gray-300 shadow-2;
}

.vertical-tabs .active svg {
  @apply fill-green-600;
}

.search-results-table tbody tr td:last-child::after {
  content: '';
  box-shadow: inset -8px 0 10px -8px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 20px;
}

.steps-list li.active::before {
  @apply bg-green-600 z-10;
}

.steps-list li.active button {
  @apply bg-green-600 text-white;
}
