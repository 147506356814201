.pagy {
  display: flex;
  font-family: sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #6b7280;

  @apply text-13/6;
}
.pagy > :not([hidden]) ~ :not([hidden]) {
  --space-reverse: 0;
  margin-right: calc(0.25rem * var(--space-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-reverse)));
}
.pagy a:not(.gap) {
  display: block;
  text-decoration: none;
  border-radius: 0.5rem;
  padding: 0.25rem 0.75rem;
  color: inherit;

  @apply border border-gray-300 rounded text-13/6 text-green-600 transition-colors;
}
.pagy a:not(.gap):hover {
  @apply border-green-600 text-white bg-green-600;
}
.pagy a:not(.gap):not([href]) { /* disabled links */
  cursor: default;
  color: #d1d5db;

  @apply hover:border-gray-300 bg-green-600/10;
}
.pagy a:not(.gap).current {
  @apply border-green-600 text-white bg-green-600 hover:border-green-600;
}
.pagy label {
  white-space: nowrap;
  display: inline-block;
  border-radius: 0.5rem;
  background-color: #e5e7eb;
  padding: 0.125rem 0.75rem;
}
.pagy label input {
  line-height: 1.5rem;
  border-radius: 0.375rem;
  border-style: none;
  background-color: #f3f4f6;
}
